import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Col
} from 'reactstrap';
import frSvg from '../../../Language/img/fr.svg';
import enSvg from '../../../Language/img/gb.svg';
import usSvg from '../../../Language/img/us.svg';


const CardJob = ({
  colnum,
  imgsrc,
  title,
  subtitle,
  language,
  location,
  description,
  status,
  link,
  textButtonId
}) => (
    <Col md={colnum} className="cardJob">
      <Card className="card">
        <Link to={link} className="link">
          {/* <div className="overlay">
            <div className="text title">{description}</div>
          </div> */}
          <CardBody className="textContainer">
            <CardTitle className="title">{title}</CardTitle>
            <CardSubtitle className="subtitle">
              {subtitle}
              {' - '}
              {language==="fr-Fr" && 
                <img src={frSvg} alt="fr" width="20" height="20"/>}
              {language==="en-Gb" && 
                <img src={enSvg} alt="en" width="20" height="20"/>}
              {language==="en-Us" && 
                <img src={usSvg} alt="us" width="20" height="20"/>}
              {' - '}
              {location}
            </CardSubtitle>
            <CardText className="description">{description}</CardText>
          </CardBody>
        </Link>
      </Card>
    </Col>
);


CardJob.propTypes = {
  colnum: PropTypes.number.isRequired,
  imgsrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default CardJob;
